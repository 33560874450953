/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on April 28, 2021 */

@font-face {
  font-family: 'hyperwave2';
  src: url('hyperwave_two-webfont.woff2') format('woff2'),
    url('hyperwave_two-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'hyperwave1';
  src: url('hyperwave_one-webfont.woff2') format('woff2'),
    url('hyperwave_one-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'hyperwave3';
  src: url('hyperwave_three-webfont.woff2') format('woff2'),
    url('hyperwave_three-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
